<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 ">
        <SendFiles
          ref="file"
          :logo="empresa.logotipo"
          @fileAddOrchanged="setNewFile($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-10">
        <b-form-group>
          <label>Razão Social</label>
          <b-input
            type="text"
            ref="razao_socialInput"
            v-model="empresa.razao_social"
            placeholder="Razão Social"
            :state="validateState('razao_social')"
          />
          <b-form-invalid-feedback>
            Informe uma razão social válida
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-2 text-center">
        <b-form-group>
          <label>Status</label>
          <b-form-checkbox
            ref="statusInput"
            v-model="empresa.status"
            class="form-control"
            :value="1"
            :unchecked-value="0"
            switch
          ></b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group>
          <label>Fantasia</label>
          <b-input
            ref="fantasiaInput"
            type="text"
            v-model="empresa.fantasia"
            placeholder="Fantasia"
            :state="validateState('fantasia')"
          />
          <b-form-invalid-feedback>
            Informe uma fantasia válida
          </b-form-invalid-feedback></b-form-group
        >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-form-group>
          <label>E-mail</label>
          <b-input
            ref="emailInput"
            type="text"
            v-model="empresa.emails"
            placeholder="E-mail"
            :state="validateState('email')"
          />
          <b-form-invalid-feedback>
            Informe um e-mail válido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group>
          <label>Sigla</label>
          <b-input
            ref="siglaInput"
            type="text"
            v-model="empresa.sigla"
            placeholder="Sigla"
            :state="validateState('sigla')"
          />
          <b-form-invalid-feedback>
            Informe uma sigla válida
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group>
          <label>CNPJ</label>
          <b-input
            type="text"
            ref="cnpjInput"
            v-model="empresa.cnpj"
            v-mask="'##.###.###/####-##'"
            placeholder="CNPJ"
            :state="validateState('cnpj')"
          />
          <b-form-invalid-feedback>
            Informe uma CNPJ válido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="mt-3 border rounded p-3">
      <b-tabs vertical class="">
        <b-tab>
          <template #title> <i class="fas fa-map"></i> Endereços </template>
          <Endereco
            showTipoLogradouro
            :enderecos="empresa.enderecos ? empresa.enderecos : []"
          />
          <b-input hidden :state="validateState('enderecos')" />
          <b-form-invalid-feedback>
            Informe ao menos um endereço</b-form-invalid-feedback
          >
        </b-tab>
        <b-tab>
          <template #title>
            <i class="fas fa-phone-alt"></i> Telefones
          </template>
          <Telefones
            :title="''"
            :telefones="empresa.telefones ? empresa.telefones : []"
          />
          <b-input hidden :state="validateState('telefones')" />
          <b-form-invalid-feedback>
            Informe ao menos um telefone</b-form-invalid-feedback
          >
        </b-tab>

        <b-tab>
          <template #title>
            <i class="fas fa-chart-pie"></i> Configurar Relatórios
          </template>

          <configurar-relatorios
            :header="empresa.relatorio.header"
            :footer="empresa.relatorio.footer"
            @header="empresa.relatorio.header = $event"
            @footer="empresa.relatorio.footer = $event"
          ></configurar-relatorios>
        </b-tab>

        <b-tab>
          <template #title>
            <i class="fas fa-file-invoice-dollar"></i> Notas Fiscais
          </template>

          <configurar-notas-fiscais
        
            :notaFiscalValue="empresa.configuracoes.nf"
            @input="empresa.configuracoes.nf = $event"
            :empresa="empresa"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import EmpresasLib from "@/libs/EmpresasLib";
import Validadores from "@/helpers/Validadores";
import Endereco from "../Enderecos/Endereco";
import Telefones from "../Telefones/Telefones";
import SendFiles from "../common/SendFiles";
import ConfigurarRelatorios from "./Relatorios/ConfigurarRelatorios.vue";
import ConfigurarNotasFiscais from "./NotasFiscais/ConfigurarNotasFiscais.vue";

export default {
  components: {
    Endereco,
    Telefones,
    SendFiles,
    ConfigurarRelatorios,
    ConfigurarNotasFiscais,
  },
  props: {
    onSaveDone: Function,
    empresa_edit: Object,
  },
  data() {
    return {
      empresa: {
        razao_social: "",
        fantasia: "",
        sigla: "",
        cnpj: "",
        status: 1,
        enderecos: [],
        telefones: [],
        emails: "",
        logotipo: "",
        empresa_id: null,
        relatorio: {
          header: "",
          footer: "",
        },
        configuracoes: {
          nf: {
            endereco: {
              codigoPais: "1058",
              descricaoPais: "Brasil",
              tipoLogradouro: "",
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              codigoCidade: "",
              estado: "",
              cep: "",
            },
            telefone: {
              ddd: "",
              numero: "",
            },
            incentivoFiscal: false,
            incentivadorCultural: false,
            regimeTributario: null,
            regimeTributarioEspecial: null,
            simplesNacional: false,
            certificado: "",
            nfse: {
              ativo: true,
              tipoContrato: 0,

              config: {
                // rps: {
                //   lote: "",
                //   numeracao: [],
                // },
                prefeitura: {
                  login: "",
                  senha: "",
                },
                email: {
                  envio: false,
                },
                producao: false,
              },
              servicos: [
                {
                  codigo: "",
                  codigoTributacao: "",
                  discriminacao: "",
                  cnae: "",
                  iss: {
                    tipoTributacao: "",
                    aliquota: 0.0,
                  },
                },
              ],
            },
          },
          cpfCnpj: "",
          inscricaoMunicipal: "",
          razaoSocial: "",
          nomeFantasia: "",
          email: "",
        },
      },
      hasErrors: [],
      loading: false,
    };
  },
  created() {
    this.$forceUpdate();
    this.clearErrors();

    if (this.empresa_edit.id) {
      this.empresa = Object.assign({},  this.empresa_edit);
      this.empresa.configuracoes = Object.assign({}, this.empresa_edit.configuracoes)
    }
  },
  methods: {
    setNewFile(event) {
      this.empresa.logotipo = event;
    },
    async editar(empresa) {
      this.empresa = empresa;
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state, label) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state, label });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    inputFocus() {
      for (let i = 0; i < Object.values(this.$refs).length; i++) {
        let input = Object.values(this.$refs);
        //console.log(input);
        if (input[i].type == "text" && input[i].value == "") {
          input[i].focus();
          break;
        }
      }
    },
    async validar() {
      this.clearErrors();
      this.addOrUpdateError(
        "razao_social",
        !Validadores.string.IsNullOrEmpty(this.empresa.razao_social),
        "Razão Social está vazia"
      );
      this.addOrUpdateError(
        "fantasia",
        !Validadores.string.IsNullOrEmpty(this.empresa.fantasia),
        "Fantasia está vazia"
      );

      this.addOrUpdateError(
        "sigla",
        !Validadores.string.IsNullOrEmpty(this.empresa.sigla),
        "Sigla está vazia"
      );

      this.addOrUpdateError(
        "cnpj",
        Validadores.string.validarCNPJ(this.empresa.cnpj),
        "CNPJ inválido"
      );

      // this.addOrUpdateError(
      //   "email",
      //   Validadores.string.IsEmail(this.empresa.emails)
      // );

      this.addOrUpdateError(
        "enderecos",
        this.empresa.enderecos.length > 0,
        "Adicione ao menos um endereço."
      );

      this.addOrUpdateError(
        "telefones",
        this.empresa.telefones.length > 0,
        "Adicione ao menos um telefone."
      );

      await this.$nextTick();
      this.inputFocus();
      console.log(this.hasErrors);
      return this.hasErrors.filter((x) => x.state == true).length >= 6;
    },
    showErrorSave(error = "") {
      this.$swal.fire({
        title: `Erro ao salvar! \n${error}`,
        icon: "error",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
      });
    },
    async salvar() {
      this.loading = true;
      await this.$nextTick();
      try {
        if (this.$refs.file.file) {
          this.empresa.logotipo = this.$refs.file.file;
        }
        const validado = await this.validar();
        //console.log("validou?", validado);

        if (validado === true) {
          const res = await EmpresasLib.storeEmpresa(this.empresa);

          if (res && res.success) {
            this.$swal.fire({
              title: "Concluído!",
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 1500,
              showConfirmButton: false,
              timerProgressBar: true,
            });

            if (this.onSaveDone) {
              this.onSaveDone();
            }
          }
        } else {
          let errDesc = await this.hasErrors
            .filter((e) => !e.state)
            .map((e) => e.label)
            .reduce((it, vl) => {
              it += vl + " <hr />";
              return it;
            }, "");

          this.$swal({
            title: "Atenção",
            html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errDesc}</span>`,
            icon: "error",
          });
        }
      } catch (e) {
        this.showErrorSave(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
